import { ref } from 'vue';
import { UAParser } from 'ua-parser-js';
import legacyBrowserConfig from '~/config/legacyBrowser.config';

const isAppleDevice = ref(false);
const isIphoneDevice = ref(false);
const isIpadDevice = ref(false);
const isIosDevice = ref(false);
const isLegacyBrowser = ref(false);
const isTouchDevice = ref(false);

export function useDeviceDetection() {
    const requestUserAgent = useRequestHeader('user-agent');
    const uaParser = UAParser(requestUserAgent ?? navigator?.userAgent).withFeatureCheck();

    isAppleDevice.value = uaParser.device.is('Apple');
    isIphoneDevice.value = uaParser.device.is('iPhone');
    isIpadDevice.value = uaParser.device.is('iPad');
    isIosDevice.value = isIphoneDevice.value || isIpadDevice.value;

    if (!import.meta.server) {
        isTouchDevice.value = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    }

    const setIsLegacyBrowser = () => {
        let isLegacy = legacyBrowserConfig.unsupported.some((browserName) => uaParser.browser.is(browserName));

        if (isLegacy) {
            isLegacyBrowser.value = isLegacy;
            return;
        }

        const minBrowserCheck = legacyBrowserConfig.minVersions.find((browser) => uaParser.browser.is(browser.name));
        const browserVersion = parseFloat(uaParser.browser.version ?? '');
        const osVersion = parseFloat(uaParser.os.version ?? '');

        if (minBrowserCheck) {
            const browserVersionIsLower =
                (minBrowserCheck.browserVersion && minBrowserCheck.browserVersion > browserVersion) || false;
            const osVersionIsLower = (minBrowserCheck.osVersion && minBrowserCheck.osVersion > osVersion) || false;

            isLegacy = browserVersionIsLower || osVersionIsLower;
        }

        isLegacyBrowser.value = isLegacy;
    };
    setIsLegacyBrowser();

    return {
        isAppleDevice,
        isIphoneDevice,
        isIpadDevice,
        isIosDevice,
        isTouchDevice,
        isLegacyBrowser,
    };
}
