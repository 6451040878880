import type { LegacyBrowserConfig } from '~/types/config/legacyBrowserConfig';

export default <LegacyBrowserConfig>{
    unsupported: ['IE', 'IEMobile', 'Opera Mini'],
    minVersions: [
        {
            name: 'Safari',
            browserVersion: 14.4,
        },
        {
            name: 'Mobile Safari',
            osVersion: 14.5,
        },
        {
            name: 'Firefox',
            browserVersion: 72,
        },
        {
            name: 'Chrome',
            browserVersion: 84,
        },
        {
            name: 'Edge',
            browserVersion: 84,
        },
        {
            name: 'Android Browser',
            browserVersion: 121,
        },
        {
            name: 'Samsung Browser',
            browserVersion: 14,
        },
        {
            name: 'Opera',
            browserVersion: 70,
        },
    ],
};
